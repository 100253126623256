import { CPHeaderLinkWrapper } from '@codeparva/gatsby-theme-layout-components';
import { FaLandmark } from '@react-icons/all-files/fa/FaLandmark';
import { Link } from 'gatsby';

export const topbardata: any = {
  hideOnMobileDevice: true, // hide topbar on mobile device
  closeButton: 'FaWindowClose', // close button for announcement bar
  showAnnouncementBar: true,
  locationType: 'single', // single or multi - single will show the phone and location icon in the navbar burger menu
  ctaText: 'Give Us a Call Today',
  locations: {
    location: {
      id: '1',
      contactDetails: {
        mailingAddress: '',
        emailID: '',
        phoneNumber: '',
        facilityDetails: {
          facilityAddress: '',
          googleMapsUrl: '',
          facilityName: '',
        },
      },
      socialIcons: [
        // {
        //   iconName: 'FaTwitter',
        //   iconsUrl: 'https://twitter.com/CatskillSS',
        // },
        // {
        //   iconName: 'FaFacebook',
        //   iconsUrl: 'https://www.facebook.com/catskillselfstorage',
        // },
        // {
        //   iconName: 'FaLinkedin',
        //   iconsUrl: '/',
        // },
      ],
    },
  },
  syrasoftEmailID: 'syrasoftsoftware@gmail.com',
  iconsConfig: {
    phoneNumberIcon: 'FaPhoneAlt',
    emailIDIcon: 'FaEnvelope',
    facilityAddressIcon: 'FaMapMarkerAlt',
    mailingAddressIcon: 'FaEnvelope',
  },
  variant: 'topbar-1',
  theme: 'dark',
  buttonsConfig: {
    text: 'Pay Rent',
    iconName: 'FaDollarSign',
    show: false,
    showOnTouchDevice: true,
    url: '/account/login',
  },
  announcementInfo: '',
};

export const navbardata: any = {
  locations: {
    location: {
      id: '1',
      socialIcons: [
        // {
        //   iconName: 'FaTwitter',
        //   iconsUrl: 'https://twitter.com/CatskillSS',
        // },
        // {
        //   iconName: 'FaFacebook',
        //   iconsUrl: 'https://www.facebook.com/catskillselfstorage',
        // },
        // {
        //   iconName: 'FaTwitter',
        //   iconsUrl: 'https://twitter.com/CatskillSS',
        // },
        // {
        //   iconName: 'FaFacebook',
        //   iconsUrl: 'https://www.facebook.com/catskillselfstorage',
        // },
        // // {
        //   iconName: 'FaLinkedin',
        //   iconsUrl: '/',
        // },
      ],
      facilityDetails: {
        facilityName: 'Twins Spires Self Storage',
        googleMapsUrl: 'https://goo.gl/maps/BB1P4Fb3LWCPwSF16',
        facilityLogo: {
          logo: '',
          renderAs: 'figure',
          altText: 'a logo provided',
          height: '',
          width: '',
        },
        facilityAddress: '',
      },
      phoneNumber: '865-769-2220',
    },
  },

  iconsConfig: {
    phoneNumberIcon: 'FaPhoneAlt',
    facilityAddressIcon: 'FaMapMarkerAlt',
  },
  GatsbyImage: FaLandmark,
  locationType: topbardata.locationType,
  LinkWrapper: Link,
  navLinks: [
    {
      // this element can be skipped if the locationType is "single"
      NavLinkText: 'View Rates',
      isNavLinkDropDown: false,
      isGrouped: false,
      linkTo: '/view-rates',
      RenderAs: CPHeaderLinkWrapper,
      newTab: true,
      navLinkDropDown: [],
    },
    {
      NavLinkText: 'Pay Rent',
      isNavLinkDropDown: false,
      isGrouped: false,
      linkTo: '/account/login',
      RenderAs: CPHeaderLinkWrapper,
      newTab: true,
      navLinkDropDown: [],
    },
    // {
    //   NavLinkText: 'Storage Tips',
    //   isNavLinkDropDown: false,
    //   isGrouped: false,
    //   linkTo: '/storage-tips',
    //   RenderAs: CPHeaderLinkWrapper,
    //   newTab: true,
    //   navLinkDropDown: [],
    // },
    // {
    //   NavLinkText: 'FAQ',
    //   isNavLinkDropDown: false,
    //   isGrouped: false,
    //   linkTo: '/faq',
    //   RenderAs: CPHeaderLinkWrapper,
    //   newTab: true,
    //   navLinkDropDown: [],
    // },
    // {
    //   NavLinkText: 'Contact Us',
    //   isNavLinkDropDown: false,
    //   linkTo: '/new-york/catskill/contact',
    //   RenderAs: CPHeaderLinkWrapper,
    //   newTab: true,
    //   navLinkDropDown: [],
    // },
  ],
  buttonsConfig: [],
};
